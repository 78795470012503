import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/MovedTogether";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveStory from "@dataResolvers/resolveStory";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
// eslint-disable-next-line no-unused-vars
import StoriesFragment from "@fragments/StoriesFragment";
// eslint-disable-next-line no-unused-vars
import SideScrollFragment from "@fragments/SideScrollFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query WeMovedTogetherQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "weMovedTogether", siteId: $siteId) {
        ... on Craft_weMovedTogether_weMovedTogether_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          introImage: image0 {
            ...ImageFragment
          }
          # accountability
          accountabilityHeading: heading1
          accountabilityCopy: copy0
          accountabilityImage: image1 {
            ...ImageFragment
          }
          # global town halls
          globalHeading: heading2
          globalSideScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          # learn together
          learnHeading: heading3
          learnDescription: descriptor1
          # wvl wellness grants
          wellnessHeading: heading4
          wellnessCopy: copy1
          wellnessImage: image2 {
            ...ImageFragment
          }
          # ef in community
          communityHeading: heading5
          communityCopy: copy2
          communityImage: image3 {
            ...ImageFragment
          }
          # stories of change summary
          storiesHeading: heading6
          storiesCopy: copy3
          storiesList {
            ...StoriesFragment
          }
          # partners
          partnersHeading: heading7
          partnersDescription: descriptor2
          # awdf/accra
          awdfHeading: heading8
          awfdSideScroll: sideScrollEmbed1 {
            ...SideScrollFragment
          }
          # dignity canada
          dignityHeading: heading9
          dignityCopy: copy7
          dignityImage: image4 {
            ...ImageFragment
          }
          # section
          sectionHeading: heading10
          sectionCopy: copy4
          # video
          videoUrl: video0
          videoButton: link0 {
            text
            url
          }
          # intentions
          intentionsHeading: heading11
          intentionsCopy: copy5
          intentions {
            ... on Craft_intentions_intention_BlockType {
              artist {
                ... on Craft_artist_default_Entry {
                  fullName: title
                  headshot: image0 {
                    ...ImageFragment
                  }
                  bio: descriptor0
                  link: link0 {
                    text
                    url
                  }
                  socials {
                    ... on Craft_socials_TableRow {
                      url
                      platform
                    }
                  }
                }
              }
              artwork {
                ...ImageFragment
              }
            }
          }
          intentionsProHeading: heading12
          intentionsProCopy: copy6
          prologueImage0: image5 {
            ...ImageFragment
          }
          prologueImage1: image6 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // intro,
    introHeading,
    introDescriptor,
    introImage,
    // accountability
    accountabilityHeading,
    accountabilityCopy,
    accountabilityImage,
    // global town halls
    globalHeading,
    globalSideScroll,
    // learning together
    learnHeading,
    learnDescription,
    wellnessHeading,
    wellnessCopy,
    wellnessImage,
    // community
    communityHeading,
    communityCopy,
    communityImage,
    // stories of change summary
    storiesHeading,
    storiesCopy,
    storiesList,
    // partners
    partnersHeading,
    partnersDescription,
    // awdf/accra
    awdfHeading,
    awfdSideScroll,
    // dignitiy canada
    dignityHeading,
    dignityImage,
    dignityCopy,
    // unnamed section
    sectionHeading,
    sectionCopy,
    // intentions video
    videoUrl,
    videoButton,
    // intentions
    intentionsHeading,
    intentionsCopy,
    intentions,
    intentionsProHeading,
    intentionsProCopy,
    prologueImage0,
    prologueImage1,
  } = entry;

  return {
    meta: {
      title,
    },
    title,
    intro: {
      heading: introHeading,
      description: introDescriptor,
      image: resolveImage(introImage),
    },
    accountability: {
      heading: accountabilityHeading,
      copy: accountabilityCopy,
      image: resolveImage(accountabilityImage),
    },
    townHalls: {
      heading: globalHeading,
      sideScroll: resolveSideScroll(globalSideScroll),
    },
    learnTogether: {
      heading: learnHeading,
      description: learnDescription,
    },
    wellness: {
      heading: wellnessHeading,
      copy: wellnessCopy,
      image: resolveImage(wellnessImage),
    },
    community: {
      heading: communityHeading,
      copy: communityCopy,
      image: resolveImage(communityImage),
    },
    stories: {
      heading: storiesHeading,
      copy: storiesCopy,
      storiesList: storiesList.map(resolveStory),
    },
    partnership: {
      heading: partnersHeading,
      description: partnersDescription,
    },
    awdf: {
      heading: awdfHeading,
      sideScroll: resolveSideScroll(awfdSideScroll),
    },
    dignity: {
      heading: dignityHeading,
      // sideScroll: resolveSideScroll(dignitySideScroll),
      copy: dignityCopy,
      image: resolveImage(dignityImage),
    },
    section: {
      heading: sectionHeading,
      copy: sectionCopy,
    },
    video: {
      videoUrl,
      button: videoButton,
    },
    intentions: {
      intro: {
        heading: intentionsHeading,
        copy: intentionsCopy,
      },
      intentions: intentions.map(intention => {
        const [artist] = intention.artist;
        const artwork = intention.artwork.map(resolveImage);

        return {
          ...intention,
          ...artist,
          headshot: resolveImage(artist?.headshot),
          artwork,
        };
      }),
      prologue: {
        heading: intentionsProHeading,
        copy: intentionsProCopy,
        image0: resolveImage(prologueImage0),
        image1: resolveImage(prologueImage1),
      },
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="WeMovedMoneyQuery"
    {...props}
  />
);

export default Page;
