import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const HeroImage = loadable(() => import("@organisms/HeroImage"));
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);
const Challenge = loadable(() => import("@organisms/Challenge"));
const IntentionProfileContainer = loadable(() =>
  import("@templates/IntentionProfileContainer")
);
const Video = loadable(() => import("@organisms/Video"));
const StoriesOfChangeSummary = loadable(() =>
  import("@templates/StoriesOfChangeSummary")
);

const DreamPage = ({ data, ...props }) => {
  const {
    title,
    intro,
    accountability,
    // townHalls,
    // learnTogether,
    wellness,
    // community,
    stories,
    partnership,
    awdf,
    dignity,
    section,
    video,
    intentions,
  } = data;

  return (
    <DefaultPageContainer {...props}>
      <Frame title={intro?.heading || title} previous="/we-moved-ideas" />
      <HeroImage {...intro} />
      {/* accountability */}
      <Section anchor="accountability">
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...accountability} smallerSpacer />
      </Section>
      {/* global/ town halls */}
      {/* <Section anchor="town-halls">
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...townHalls} headingAlign="center" />
      </Section> */}
      {/* learning together */}
      <Section anchor="learning">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        {/* <SectionIntro {...learnTogether} bottomIcon /> */}
        {/* <div className="relative z-10 border-b-3 border-white md:ml-14" /> */}
        <GrowingImageCopy {...wellness} smallerSpacer />
      </Section>
      {/* ef in community */}
      {/* <Section anchor="ef-in-community">
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...community} smallerSpacer />
      </Section> */}
      {/* stories of change */}
      <Section anchor="ef-in-community">
        <StoriesOfChangeSummary {...stories} />
      </Section>
      {/* partnership */}
      <Section anchor="partnership">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <SectionIntro {...partnership} bottomIcon />
        <div className="relative z-10 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...awdf} headingAlign="center" />
      </Section>
      {/* dignity */}
      <Section anchor="dignity">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...dignity} smallerSpacer />
        {/* <GrowingImageSlide {...dignity} headingAlign="center" /> */}
      </Section>
      {/* section */}
      <Section anchor="stories-of-change">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <Challenge {...section} twoColumns />
      </Section>
      {/* video */}
      <Video anchor="intentions-video" {...video} />
      {/* intenions */}
      <Section anchor="intentions">
        <IntentionProfileContainer {...intentions} />
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
