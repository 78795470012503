import resolveImage from "./resolveImage";

const resolveStory = s => {
  const { title, image, bodyImage } = s;
  return {
    ...s,
    meta: {
      title,
    },
    heading: title,
    image: resolveImage(image),
    bodyImage: resolveImage(bodyImage),
    // backgroundImage: resolveImage(bodyImage),
  };
};

export default resolveStory;
